@import "../../../styles/variables";
@import "../../../styles/mixins";

$font-size-button-xs: 0.875rem;
$font-size-button-md: 0.7rem;
$font-size-button-lg: 0.8rem;

.button {
  padding: 14px 16px;
  font-family: $font-open-sans-family;
  font-size: $font-size-button-xs;
  text-transform: uppercase;

  @include for-md-down {
    padding: 7px 16px;
  }

  @include for-md {
    font-size: $font-size-button-md;
    padding: 8px 16px;
  }

  @include for-lg {
    font-size: $font-size-button-lg;
    padding: 14px 16px;
  }
}

.button--outlined {
  border-color: $red;
  color: $red;
}

.button--contained {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  color: $white;
  background-color: $red;
}

.button--contained:hover {
  background-color: $red-light;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}

.button--contained:active {
  background-color: $red-light;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.icon-button {
  color: $black;
}
