@import "../../styles/mixins";
@import "../../styles/variables";

$font-size-sub-text-xs: 1.125rem;
$font-size-sub-text-sm: 1.25rem;

.sub__text {
  margin-bottom: 56px;
  font-size: $font-size-sub-text-xs;
  color: $red-dark;
  font-family: $font-open-sans-family;
  font-weight: $font-weight-light;

  @include for-sm {
    font-size: $font-size-sub-text-sm;
  }

  @include for-md {
    margin-bottom: 72px;
  }
}

.document-card {
  padding: 0 8px;

  @include for-md {
    padding: 0 28px;
  }

  &:not(:first-child) {
    @include for-sm-down {
      margin-top: 32px;
    }
  }
}
