@import "./variables";
@import "./mixins";

$size-content-width: 1140px;

.background-frame {
  background-size: cover;
  background-position: center;
}

.background-frame--margin {
  @include for-md {
    margin: 72px 0;
  }
}

.background--height {
  min-height: 200px;

  @include for-sm {
    min-height: 311px;
  }

  @include for-md {
    min-height: 397px;
  }

  @include for-lg {
    min-height: 440px;
  }
}

.content-frame {
  max-width: $size-content-width;
}

.content-margin {
  @include for-sm {
    margin-left: 16px;
    margin-right: 16px;
  }

  @include for-md {
    margin-left: 32px;
    margin-right: 32px;
  }
}

.frame-padded {
  max-width: $size-content-width;
  margin-left: 16px;
  margin-right: 16px;

  @include for-sm {
    margin-left: 32px;
    margin-right: 32px;
  }

  @include for-sm {
    margin-left: 32px;
    margin-right: 32px;
  }

  @include for-md {
    margin-left: 64px;
    margin-right: 64px;
  }
}

.content-frame-padded {
  margin-top: 72px;
  margin-bottom: 72px;
  @extend .frame-padded;
}

.top-margin__large {
  margin-top: 72px;

  @include for-md {
    margin-top: 144px;
  }
}

.top-margin__small {
  margin-top: 56px;

  @include for-md {
    margin-top: 72px;
  }
}

.bottom-margin__large {
  margin-bottom: 72px;

  @include for-md {
    margin-bottom: 144px;
  }
}

.bottom-margin__small {
  margin-bottom: 56px;

  @include for-md {
    margin-bottom: 72px;
  }
}
