@import "./styles/frame";
@import "./styles/headers";
@import "./styles/mixins";
@import "./styles/variables";
@import "./styles/card";
@import "./styles/spacing";
@import "./styles/fonts";

$line-height-p-xs: 1.5rem;
$font-size-default-p-xs: 1rem;

body,
html {
  font-family: $font-open-sans-family;
  font-size: $font-size-default;
  font-weight: $font-weight-regular;

  @include for-md-down {
    font-size: $font-size-mobile;
  }
}

body {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}

div#root {
  &>div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

p {
  font-family: $font-lato-family;
  font-weight: $font-weight-regular;
  font-size: $font-size-default-p-xs;
  line-height: $line-height-p-xs;
  margin-block-start: 16px;
  margin-block-end: 16px;
}

.position-relative {
  position: relative;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

.MuiTooltip-tooltip {
  font-family: $font-lato-family;
}