@import '../../styles/variables';

.inner-container {
    display: flex;
    max-width: 1140px;

}

.outer-container {
    background-color: $grey-light;
    z-index: 2;
    border-bottom: $grey 1px solid;
}

.environment {
    color: $red;
    font-weight: bold;
    padding-right: 16px;
    text-transform: uppercase;
}

.version {
    color: $red;
    padding-right: 16px;
}

.spacer {
    flex: 1 1 auto;
}

.atrias-link {
    padding-left: 22px;
    color: $red;
    font-weight: bold;
}