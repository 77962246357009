@mixin for-sm {
  @media (min-width: 600px) { @content; }
}

@mixin for-sm-down {
  @media (max-width: 599px) { @content; }
}

@mixin for-md {
  @media (min-width: 960px) { @content; }
}

@mixin for-md-down {
  @media (max-width: 959px) { @content; }
}

@mixin for-lg {
  @media (min-width: 1280px) { @content; }
}

@mixin for-lg-down {
  @media (max-width: 1279px) { @content; }
}

@mixin for-xl {
  @media (min-width: 1920px) { @content; }
}

@mixin for-xl-down {
  @media (max-width: 1919px) { @content; }
}