@import "../../styles/variables";
@import "../../styles/mixins";

.document-app-bar-color {
  background-color: $white;
}

.tab-primary {
  font-family: $font-open-sans-family;
  color: $grey-dark;
  text-transform: uppercase;
  font-size: $font-size-documentation-xs;

  @include for-md-down {
    font-size: $font-size-documentation-sm;
  }
}

.tab-frame {
  background-color: $grey-light;
  min-height: 600px;
}

.document-tab-selector {
  border-bottom: $red 2px solid;
  color: $red;
}

.tabs-container {
  background-color: transparent;
  border-bottom: 2px solid $grey-light;
}

.push-up-1 {
  margin-top: -1px;
}

.search-info-text {
  padding-top: 88px;
  text-align: center;
}

.no-search-info-text {
  @extend .search-info-text;
  padding-top: 144px;
}

.search-background {
  background-image: url("../../assets/images/backgrounds/search-top.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
