@import "./variables";
@import "./mixins";

$font-size-h1-xs: 1.5rem;
$font-size-h2-xs: 1.125rem;
$font-size-h2-md: 1.25rem;
$font-size-intro-text-sm: 1.25rem;

h1 {
  color: $red;
  font-size: $font-size-h1-xs;
  font-family: $font-open-sans-family;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

h2 {
  color: $red;
  font-size: $font-size-h2-xs;
  font-family: $font-open-sans-family;
  font-weight: bold;
  padding: 0;
  margin: 0;

  @include for-md {
    font-size: $font-size-h2-md;
  }
}

.jumbotron__title {
  color: $white;
  font-family: $font-open-sans-family;
  font-size: $font-size-jumbotron-xs;
  font-weight: bold;
  margin: 0;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75);
  line-height: initial;

  @include for-md-down {
    padding: 16px;
  }

  @include for-lg {
    font-size: $font-size-jumbotron-lg;
  }
}

.jumbotron__title--red {
  color: $red;
  text-shadow: none;
  padding: 0;
  text-align: left;
  font-size: $font-size-jumbotron-xs;

  @include for-lg {
    font-size: $font-size-jumbotron-lg;
  }
}

.intro-text {
  color: $red-dark;
  font-weight: $font-weight-light;
  font-size: $font-size-intro-text-sm;
  font-family: $font-open-sans-family;
}
