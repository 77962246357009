@import "../../../styles/variables";
@import "../../../styles/mixins";

$font-size-footer-header-xs: 1rem;
$font-size-footer-header-md: 0.8rem;

.FooterHeader {
  font-family: $font-open-sans-family;
  font-size: $font-size-footer-header-xs;
  font-weight: $font-weight-semibold;
  color: $black;

  @include for-md {
    font-size: $font-size-footer-header-md;
  }
}
