@import "../../styles/variables";
@import "../../styles//mixins";

.footer {
    background-color: $white;
    flex-grow: 1;
}

.footer-hidden {
    display: none;
}
