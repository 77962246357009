$font-open-sans-family: 'Open Sans', sans-serif;
$font-lato-family: 'Lato', sans-serif;

$font-size-default: 20px;
$font-size-mobile: 16px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$black: #000000;
$red-dark: #991425;
$red: #af1f22;
$red-light: #ce3135;
$red-pale: #f2dada;
$grey-darkest: #686868;
$grey-dark: #979797;
$grey: #d3d3d3;
$grey-light: #e4eaf2;
$grey-lighter: #d3d3d3;
$white: #ffffff;
$hover: #f5f5f5;
$blue-app: #096790;

// SHARED FONT SIZING
$font-size-navigation-item-xs: 1rem;

$font-size-footer-xs: 0.9375rem;
$font-size-footer-md: 0.75rem;

$font-size-table-text-xs: 0.8rem;

$font-size-documentation-xs: 0.8rem;
$font-size-documentation-sm: 0.9rem;

$font-size-jumbotron-xs: 1.5rem;
$font-size-jumbotron-lg: 2.6rem;