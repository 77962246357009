@import "../../../styles/variables";
@import "../../../styles/mixins";

.FooterText {
  color: $black;
  font-family: $font-open-sans-family;
  font-size: $font-size-footer-xs;

  @include for-md {
    font-size: $font-size-footer-md;
  }
}