@import "../../styles/mixins";
@import "../../styles/variables";

.content {
    flex: 1 0 auto;
}

.content-sub-expanded {
    @include for-md {
        margin-top: -44px;
    }
}

.content-hidden {
    display: none;
}
