@import "./mixins";

.card-large {
    min-width: 100%;
    border-radius: 8px;
}

.card-large-content {
    padding: 16px;

    @include for-md {
        padding: 32px;
    }
}

.card-large-actions {
    padding: 16px;

    @include for-md {
        padding: 32px;
    }
}
